import React from "react";
import "./Meals.css";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Card, CardContent, Typography } from "@mui/material";

export interface MealsProps {
  dateEndTime: number;
  dateStartTime: number;
  dateNowTime: number;
}

const Meals: React.FC<MealsProps> = (props: {
  dateEndTime: number;
  dateStartTime: number;
  dateNowTime: number;
}) => {
  const millisecondsDay = 86400000;
  const millisecondsHour = 60000;
  const [dateNow, setDateNow] = React.useState<number>(-1);

  const [totalDays, setTotalDays] = React.useState<number>(-1);
  const [remainingDays, setRemainingDays] = React.useState<number>(-1);

  const [totalMeals, setTotalMeals] = React.useState<number>(-1);
  const [remainingMeals, setRemainingMeals] = React.useState<number>(-1);

  const [meals, setMeals] = React.useState<JSX.Element[]>([]);

  React.useEffect(() => {
    if (props.dateEndTime > 0 && dateNow > 0) {
      setRemainingDays((props.dateEndTime - dateNow) / millisecondsDay);
    }
  }, [dateNow, props.dateEndTime]);

  React.useEffect(() => {
    const shakesDaily = 6;
    setTotalMeals(totalDays * shakesDaily);
    setRemainingMeals(remainingDays * shakesDaily);
  }, [totalDays, remainingDays]);

  React.useEffect(() => {
    const mealCollection: JSX.Element[] = [];
    const mealsEliminated: number = totalMeals - remainingMeals;
    const mealEliminatedCollection: JSX.Element[] = [];
    const mealRemainingCollection: JSX.Element[] = [];
    let mealIcon: JSX.Element;
    for (let i = 0; i < totalMeals; i++) {
      if (i < mealsEliminated) {
        if (i % 2 === 0) {
          mealIcon = (
            <RestaurantIcon
              key={i}
              sx={{ color: "rgba(63, 94, 251, 1)" }}
              color="disabled"
            />
          );
        } else {
          mealIcon = (
            <RestaurantIcon
              key={i}
              sx={{ color: "rgba(252,70,107,1)" }}
              color="disabled"
            />
          );
        }
        mealEliminatedCollection.push(mealIcon);
      } else {
        mealIcon = <RestaurantIcon key={i} color="disabled" />;
        mealRemainingCollection.push(mealIcon);
      }
    }

    if (mealsEliminated < totalMeals) {
      mealCollection.push(...mealRemainingCollection, ...mealEliminatedCollection)
    } else {
      mealCollection.push(...mealEliminatedCollection, ...mealRemainingCollection)
    }

    setMeals(mealCollection);
  }, [totalMeals, remainingMeals]);

  React.useEffect(() => {
    if (
      props.dateEndTime > 0 &&
      props.dateNowTime > 0 &&
      props.dateStartTime > 0
    ) {
      setTotalDays((props.dateEndTime - props.dateStartTime) / millisecondsDay);
      setRemainingDays(
        (props.dateEndTime - props.dateNowTime) / millisecondsDay
      );
      const interval = setInterval(
        () => setDateNow(Date.now()),
        millisecondsHour
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, [props.dateNowTime, props.dateEndTime, props.dateStartTime]);

  return (
    <div className="Shake-elimination">
      <div className="Shake-numbers">
        <Card sx={{ height: "auto" }}>
          <CardContent>
            <Typography variant="h3" component="div">
              {remainingMeals > -1 ? Math.ceil(remainingMeals) : "000"}
            </Typography>
            <Typography sx={{}} color="text.secondary">
              meals remaining
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ height: "auto" }}>
          <CardContent>
            <Typography variant="h3" component="div">
              {totalMeals > -1 ? totalMeals : "000"}
            </Typography>
            <Typography sx={{}} color="text.secondary">
              meals total
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className="Shake-icons">
        {meals.length > 0 ? (
          <Card sx={{ height: "auto" }}>
            <CardContent>
              <Typography sx={{}} color="text.secondary">
                {meals}
              </Typography>
            </CardContent>
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export default Meals;
