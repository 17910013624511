import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "./Countdown.css";

export interface CountdownProps {
  dateEndTime: number;
  dateNowTime: number;
}

const Countdown: React.FC<CountdownProps> = (props: {
  dateEndTime: number;
  dateNowTime: number;
}) => {
  const [timeToEnd, setTimeToEnd] = React.useState<number>(-1);
  const [hoursRemaining, setHoursRemaining] = React.useState<number>(-1);
  const [minutesRemaining, setMinutesRemaining] = React.useState<number>(-1);
  const [secondsRemaining, setSecondsRemaining] = React.useState<number>(-1);

  React.useEffect(() => {
    if (props.dateEndTime !== 0 && props.dateNowTime !== 0) {
      setTimeToEnd(props.dateEndTime - props.dateNowTime);
    }
  }, [props.dateNowTime, props.dateEndTime]);

  React.useEffect(() => {
    setHoursRemaining(timeToEnd % 86400000);
  }, [timeToEnd]);

  React.useEffect(() => {
    setMinutesRemaining(hoursRemaining % 3600000);
  }, [hoursRemaining]);

  React.useEffect(() => {
    setSecondsRemaining(minutesRemaining % 60000);
  }, [minutesRemaining]);

  return (
    <div className="Countdown">
      <Card sx={{ height: "auto", mr: 2 }}>
        <CardContent>
          <Typography variant="h3" component="div">
            {timeToEnd > -1 ? Math.floor(timeToEnd / 86400000) : "00"}
          </Typography>
          <Typography sx={{}} color="text.secondary">
            days
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ height: "auto", mr: 2 }}>
        <CardContent>
          <Typography variant="h3" component="div">
            {hoursRemaining > -1 ? Math.floor(hoursRemaining / 3600000) : "00"}
          </Typography>
          <Typography sx={{}} color="text.secondary">
            hours
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ height: "auto", mr: 2 }}>
        <CardContent>
          <Typography variant="h3" component="div">
            {minutesRemaining > -1
              ? Math.floor(minutesRemaining / 60000)
              : "00"}
          </Typography>
          <Typography sx={{}} color="text.secondary">
            minutes
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ height: "auto" }}>
        <CardContent>
          <Typography variant="h3" component="div">
            {secondsRemaining > -1 ? Math.floor(secondsRemaining / 1000) : "00"}
          </Typography>
          <Typography sx={{}} color="text.secondary">
            seconds
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Countdown;
