import {
  AppBar,
  CircularProgress,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import "./App.css";
import Countdown from "./Countdown";
import Meals from "./Meals";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const App: React.FC<any> = () => {
  const [dateStartTime] = React.useState<number>(
    new Date("March 8, 2023 00:00").getTime()
  );
  const [dateEndTime] = React.useState<number>(
    new Date("May 3, 2023 01:00").getTime()
  );
  const [dateNowTime, setDateNowTime] = React.useState<number>(0);

  React.useEffect(() => {
    const interval = setInterval(() => setDateNowTime(Date.now()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="App">
      <AppBar position="static" sx={{ bgcolor: "rgba(252,70,107,1)" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <WorkspacePremiumIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Astrids NUPO Rejse
            </Typography>
            <WorkspacePremiumIcon
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Astrids NUPO Rejse
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <div className="App-content">
        <React.Suspense fallback={<CircularProgress color="secondary" />}>
          <Countdown dateEndTime={dateEndTime} dateNowTime={dateNowTime} />
        </React.Suspense>
        <React.Suspense fallback={<CircularProgress color="secondary" />}>
          <Meals
            dateEndTime={dateEndTime}
            dateStartTime={dateStartTime}
            dateNowTime={dateNowTime}
          />
        </React.Suspense>
      </div>
    </div>
  );
};

export default App;
